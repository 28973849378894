<template>
	<div id="k" style="width: 100%">
		<div v-for="(v, i) in timelines" :key="i" class="app">
			<p class="pl-2" style="width: 65%">{{ v.title }}</p>
			<p class="text-right" style="width: 35%">{{ v.timestamp }}</p>
		</div>
	</div>
</template>

<script>
	import { onMounted } from "@vue/composition-api";

	export default {
		name: "index",
		props: {
			timelines: Array,
		},
		setup() {
			onMounted(() => {});
		},
	};
</script>

<style lang="scss" scoped>
	.app {
		display: flex;
		border-left: 0.2rem solid #169bd5;
		padding-bottom: 0.5rem;
		position: relative;

		&:before {
			position: absolute;
			content: "";
			width: 1.6rem;
			height: 1.6rem;
			transform: translateX(-50%);
			left: -0.1rem;
			border-radius: 50%;
			background: #009dd9;
			border: 1px solid white;
			box-shadow: 0 0 0 1px #a1a1a1;
		}

		p {
			word-break: break-all;
		}
	}
</style>
